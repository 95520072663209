<template>
  <div class="p-4">
    <showing-component></showing-component>
  </div>
</template>

<script>
import showingComponent from "../src/components/showingComponent.vue";

export default {
  components: { showingComponent },
};
</script>

<style></style>
