<template>
  <div>
    <searching-btn-vue @search="handleSearch"></searching-btn-vue>
    <!-- Profile -->
    <div class="grid grid-cols-2 gap-2 md:grid-cols-4 my-4">
      <div
        class="border-2 border-jet_black rounded-md max-h-28"
        v-for="(x, index) in allModel"
        :key="index"
        @click="modelInfo(x._id)"
      >
        <!-- Cover -->
        <div class="w-full z-0">
          <img
            class="h-16 opacity-70 object-cover rounded-md w-full"
            :src="x.cover"
            alt=""
          />
        </div>
        <div class="flex items-center -mt-5 relative z-50">
          <!-- Background -->
          <div
            class="absolute bottom-0 left-0 w-full h-4/5 bg-gray-500 z-0"
          ></div>
          <!-- Profile Image Container -->
          <div class="fflex-shrink-0 h-16 w-16 relative z-10">
            <img
              class="h-full w-full rounded-full object-cover object-center"
              :src="x.imgUrl"
              alt="Profile Image"
            />
          </div>

          <!-- Name -->
          <div class="ml-4 relative z-10">
            <div class="flex items-center">
              <svg
                class="text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                width="1rem"
                height="1rem"
                viewBox="0 0 24 24"
              >
                <g fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
                  <path
                    d="M16 9a4 4 0 1 1-8 0a4 4 0 0 1 8 0m-2 0a2 2 0 1 1-4 0a2 2 0 0 1 4 0"
                  />
                  <path
                    d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11s11-4.925 11-11S18.075 1 12 1M3 12c0 2.09.713 4.014 1.908 5.542A8.986 8.986 0 0 1 12.065 14a8.984 8.984 0 0 1 7.092 3.458A9 9 0 1 0 3 12m9 9a8.963 8.963 0 0 1-5.672-2.012A6.992 6.992 0 0 1 12.065 16a6.991 6.991 0 0 1 5.689 2.92A8.964 8.964 0 0 1 12 21"
                  />
                </g>
              </svg>
              <h1 class="text-white font-bold text-md ml-2">{{ x.name }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between mt-4">
      <button
        :disabled="currentPage == 1"
        @click="prevBtn"
        class="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-green-500 rounded-lg"
      >
        Prev
      </button>
      <div class="flex">
        <h1 class="px-4 py-2 font-medium tracking-wide text-white">
          {{ currentPage }}
        </h1>
        <h1 class="px-4 py-2 font-medium tracking-wide text-white">/</h1>
        <h1 class="px-4 py-2 font-medium tracking-wide text-white">
          {{ totalPages }}
        </h1>
      </div>
      <button
        @click="nextBtn"
        class="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-green-500 rounded-lg"
        :disabled="currentPage == totalPages"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SearchingBtnVue from "../src/components/SearchingBtn.vue";
export default {
  components: { SearchingBtnVue },
  data() {
    return {
      allModel: [],
      currentPage: 1,
      totalPages: null,
    };
  },
  mounted() {
    this.fetchModel();
  },
  watch: {
    selectedOption() {
      this.currentPage = 1; // Reset to the first page when the option changes
      this.fetchModel();
    },
    currentPage() {
      this.fetchModel();
    },
  },
  methods: {
    async fetchModel() {
      const response = await axios.get(
        process.env.VUE_APP_BACKEND_API +
          `/api/actors/all?page=${this.currentPage}`
      );
      this.allModel = response.data.allActor;
      this.totalPages = response.data.totalPages;
    },
    prevBtn() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchModel();
      }
    },
    nextBtn() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchModel();
      }
    },
    async search() {
      const response = await axios.get(
        process.env.VUE_APP_BACKEND_API +
          `/api/actors/search?query=${this.searching}`
      );
      this.allModel = response.data;
    },
    async handleSearch(query) {
      this.searching = query;
      await this.search();
    },
    modelInfo(id) {
      this.$router.push({ name: "modelshowing", params: { id: id } });
    },
  },
};
</script>
