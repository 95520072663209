<template>
  <div class="shadow-md">
    <div class="border-y-2 p-2">
      <router-link to="/video" class="text-larger font-bold"
        >Trending</router-link
      >
    </div>
    <!-- Popular video generate -->
    <div class="grid grid-cols-2 gap-4 md:grid-cols-5">
      <div
        class="overflow-hidden rounded-lg shadow mt-4 hover:shadow-lg transition-transform duration-2 hover:scale-105 cursor-pointer"
        v-for="(x, index) in allPopularVideo"
        :key="index"
        @click="getDataId(x._id)"
      >
        <div class="relative">
          <img alt="" :src="x.thumbnailUrl" class="h-44 w-72 object-cover" />
          <h1 class="absolute bottom-2 right-2 px-2 bg-jet_black rounded-md">
            {{ x.duration }}
          </h1>
        </div>
        <div class="bg-jet_black p-4 min-h-40 sm:p-6 md:min-h-40">
          <span class="flex justify-between text-sm">
            <p>{{ formatDate(x.createdAt) }}</p>
            <p class="text-sm">View : {{ viewConvert(x.view) }}</p>
          </span>

          <h3 class="mt-0.5 text-lg font-bold">
            {{ limitLength(x.title, 50) }}
          </h3>
        </div>
      </div>
    </div>
    <span class="flex justify-end my-4">
      <router-link
        to="/video"
        class="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-green-500 rounded-lg hover:bg-green-600 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
      >
        Show more
      </router-link>
    </span>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      allPopularVideo: [],
    };
  },
  mounted() {
    this.fetchPopular();
  },
  methods: {
    async fetchPopular() {
      const response = await axios.get(
        process.env.VUE_APP_BACKEND_API + "/api/videos/popular"
      );
      this.allPopularVideo = response.data.sortByPopular.slice(0, 10);
    },
    async getDataId(id) {
      try {
        // Open the ad link in a new popup window

        // Navigate to a new route after the popup is opened
        this.$router.push({
          name: "showing",
          params: { id: id },
        });
      } catch (error) {
        console.error("Error opening popup or navigating:", error);
        // Handle any errors or fallback logic here
      }
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    viewConvert(x) {
      if (x > 1000) {
        return (x / 1000).toFixed(1) + "k";
      }
      return x;
    },
    limitLength(text, maxLength) {
      if (!text) {
        return "";
      }
      return text.length <= maxLength
        ? text
        : text.substring(0, maxLength) + "...";
    },
  },
};
</script>
