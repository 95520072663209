<template>
  <div>
    <footer>
      <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-8">
        <div class="sm:flex sm:items-center sm:justify-between">
          <div class="flex justify-center sm:justify-start">
            <img class="w-12" src="../assets/vip.png" alt="" />
            <h1 class="m-3 text-gray-400">VVIPGirls.online</h1>
          </div>

          <div class="text-green-700 text-center hover:text-green-500">
            <a href="/rule">Terms & Conditions</a>
          </div>

          <p
            class="mt-4 text-center text-sm text-gray-500 lg:mt-0 lg:text-right"
          >
            Copyright &copy; 2022. All rights reserved.
          </p>
        </div>
        <!-- Group media -->
        <div class="flex">
          <a href="https://t.me/VVIPGirls" target="_blank">
            <svg
              class="mt-4 ml-2 text-gray-400 hover:text-gray-200 cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="2rem"
              height="2rem"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2m4.64 6.8c-.15 1.58-.8 5.42-1.13 7.19c-.14.75-.42 1-.68 1.03c-.58.05-1.02-.38-1.58-.75c-.88-.58-1.38-.94-2.23-1.5c-.99-.65-.35-1.01.22-1.59c.15-.15 2.71-2.48 2.76-2.69a.2.2 0 0 0-.05-.18c-.06-.05-.14-.03-.21-.02c-.09.02-1.49.95-4.22 2.79c-.4.27-.76.41-1.08.4c-.36-.01-1.04-.2-1.55-.37c-.63-.2-1.12-.31-1.08-.66c.02-.18.27-.36.74-.55c2.92-1.27 4.86-2.11 5.83-2.51c2.78-1.16 3.35-1.36 3.73-1.36c.08 0 .27.02.39.12c.1.08.13.19.14.27c-.01.06.01.24 0 .38"
              />
            </svg>
          </a>
          <a href="mailto:leeeuntae04@gmail.com">
            <svg
              class="mt-4 ml-2 text-gray-400 hover:text-gray-200 cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="2rem"
              height="2rem"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.587 1.413T20 20zm8-7l8-5V6l-8 5l-8-5v2z"
              />
            </svg>
          </a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
