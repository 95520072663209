<template>
  <div class="flex my-4 gap-2">
    <!-- Searching -->
    <input
      v-model="query"
      class="w-full h-12 px-4 rounded-lg border text-black border-gray-300 shadow-sm focus:outline-none focus:border-blue-500"
      type="text"
      placeholder="Search title"
      @keydown.enter="search"
    />
    <button
      @click="search"
      class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline"
    >
      Search
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      query: "",
    };
  },
  methods: {
    search() {
      this.$emit("search", this.query);
    },
  },
};
</script>

<style></style>
