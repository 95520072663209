<template>
  <div class="px-20 py-10 grid grid-flow-row gap-y-4">
    <div>
      <strong>Terms Of Service </strong>
      <p>Last Modified: Jul 5, 2024</p>
    </div>

    <div>
      <p class="text-gray-400 text-sm">
        <strong>NOTE :</strong> These Terms of Service were drafted in English.
        If these Terms of Service are offered in any language other than English
        it shall be for information purposes only and, in case of conflict
        between the English-language version and any translation thereof, the
        English-language version will prevail.
      </p>
    </div>

    <div class="flex justify-center">
      <strong class="text-larger underline decoration-solid"
        >SUMMARY TERMS OF SERVICE</strong
      >
    </div>

    <div class="text-gray-300 text-smallest">
      <p>
        The following summary provides an overview for your convenience of
        VVIPGirls's most important terms that govern your use of VVIPGirls
        <span class="underline decoration-white">
          (www.VVIPGirls.online, “the Website”) </span
        >. This is a non-binding summary of the applicable Terms of Service of
        the Website, that covers selected aspects of the same in a simplified
        manner. It is not a substitute for our binding Terms of Service, which
        include our policies and guidelines (“our Terms”). In all cases, our
        Terms prevail.
      </p>
      <p>
        NOTE : Any capitalized terms not defined herein shall bear the meaning
        attributed to them in the Terms.
      </p>

      <div class="mt-6">
        <ol class="ml-4">
          <li>
            <strong>a. Acceptance:</strong> By accessing, using, or visiting the
            Website, and/or any of its Content, functionalities, and services,
            you communicate your agreement to our Terms.
          </li>
          <li>
            <strong>b. Age and Restrictions</strong>: You affirm that you are at
            least eighteen (18) years of age or the age of majority in the
            jurisdiction you are accessing the Website from. You also represent
            that the jurisdiction from which you access the Website does not
            prohibit the receiving or viewing of sexually explicit Content.
          </li>
          <li>
            <strong>c. Third party content:</strong> When using the Website, you
            may be exposed to Content from a variety of sources for which we are
            not responsible. We also assume no responsibility for third party
            websites that may be accessed via links found, placed, or otherwise
            displayed on the Website.
          </li>
          <li>
            <strong>d. Reporting:</strong> Users are urged to report Content
            they suspect of being illegal or in violation of our Terms.
          </li>
          <li>
            <strong>e. Limitation of Liability:</strong> Our liability is
            limited, as provided in our Terms.
          </li>
          <li>
            <strong>f. Termination:</strong> We may terminate our Terms for any
            or no reason at any time by notifying you through a notice on this
            Website, by email, or by any other method of communication.
          </li>
          <li>
            <strong>g. Legal Compliance: </strong>We adhere strictly to all
            applicable laws. If any harmful content is found on our website, we
            will take prompt action to address it and comply with any legal
            requirements. Should the website's condition be deemed harmful or
            non-compliant, we reserve the right to shut it down immediately to
            ensure the safety and compliance of our operations.
          </li>
          <li>
            <strong>h. Copyright (OnlyFans): </strong> If you find your video on
            this site and wish to have it removed, please submit a request for
            deletion. We respect your rights and will take prompt action to
            prevent further distribution.
          </li>
        </ol>
        <p class="ml-8 my-4">
          The full Terms of Service can be found below and you are advised to
          read them before using the Website and accessing any of its Content,
          functionalities and services.
        </p>

        <ol class="ml-4">
          <li>
            5. Trust and Safety: This section includes the applicable policies
            and guidelines that all Users should be complying with, including,
            but not limited to:
            <h4 class="ml-4 mt-4">
              <strong>a. Policies and Related Guidelines:</strong> The below
              policies and guidelines illustrate our stance against several
              prohibited actions, that all Users should abide by when using the
              Website. Failure to comply with the below policies and guidelines
              might lead to adverse consequences including, inter alia,
              termination of your account. p
              <div class="ml-4 grid grid-flow-row gap-y-1 mt-2">
                <li>-Child Sexual Abuse Material Policy</li>
                <li>-Non-Consensual Content Policy</li>
                <li>-Violent Content Policy</li>
                <li>-Hate Speech Policy</li>
                <li>-Animal Welfare Policy</li>
                <li>-Copyright</li>
                <li>-Community Guidelines</li>
              </div>
              <strong>c. Reporting Abuse and Violations:</strong>
              <div class="ml-4 grid grid-flow-row gap-y-1 mt-2">
                <li>
                  -Report harmful content : You can report it by sending email
                  via mail:
                  <strong class="underline decoration-white"
                    ><a href="mailto:leeeuntae04@gmail.com"
                      >leeeuntae04@gmail.com
                    </a></strong
                  >. or via whatsapp
                  <strong class="underline decoration-white"
                    >(+1) 574 914-1990</strong
                  >
                </li>
              </div>
            </h4>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
