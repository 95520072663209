<template>
  <div class="bg-black pb-2">
    <div class="px-4 flex justify-between">
      <div class="mt-4 text-larger ml-8">
        <h1>VVIPGIRLS</h1>
      </div>
      <div>
        <router-link to="/">
          <img class="w-20 h-16" src="../assets/vip.png" alt="" />
        </router-link>
      </div>
      <div class="mt-4" @click="openBtn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2rem"
          height="2rem"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5A6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5S14 7.01 14 9.5S11.99 14 9.5 14"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    openBtn() {
      this.$router.push("/video");
    },
  },
};
</script>
