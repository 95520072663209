<template>
  <div>
    <searching-btn-vue @search="handleSearch"></searching-btn-vue>
    <div class="flex justify-between">
      <h1 class="text-larger font-bold border-b-2">{{ selectedOption }}</h1>

      <select
        name="HeadlineAct"
        class="mt-1.5 rounded-lg px-4 border-gray-300 text-gray-700 text-sm cursor-pointer"
        v-model="selectedOption"
      >
        <option value="Recently">Recently</option>
        <option value="Popular">Popular</option>
      </select>
    </div>

    <div class="grid grid-cols-2 gap-4 md:grid-cols-5">
      <div
        class="overflow-hidden rounded-lg shadow mt-4 hover:shadow-lg transition-transform duration-200 hover:scale-105 cursor-pointer"
        v-for="(x, index) in videosData"
        :key="index"
        @click="getvideo(x._id)"
      >
        <div class="relative">
          <img alt="" :src="x.thumbnailUrl" class="h-44 w-72 object-cover" />
          <h1 class="absolute bottom-2 right-2 px-2 bg-jet_black rounded-md">
            {{ x.duration }}
          </h1>
        </div>
        <div class="bg-jet_black p-4 min-h-40 sm:p-6 md:min-h-40">
          <span class="flex justify-between text-sm">
            <p>{{ formatDate(x.createdAt) }}</p>
            <p class="text-sm">View : {{ x.view }}</p>
          </span>

          <h3 class="mt-0.5 text-lg font-bold">
            {{ limitLength(x.title, 60) }}
          </h3>
        </div>
      </div>
    </div>
    <div class="flex justify-between mt-4">
      <button
        :disabled="currentPage == 1"
        @click="prevBtn"
        class="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-green-500 rounded-lg"
      >
        Prev
      </button>
      <div class="flex">
        <h1 class="px-4 py-2 font-medium tracking-wide text-white">
          {{ currentPage }}
        </h1>
        <h1 class="px-4 py-2 font-medium tracking-wide text-white">/</h1>
        <h1 class="px-4 py-2 font-medium tracking-wide text-white">
          {{ totalPages }}
        </h1>
      </div>
      <button
        @click="nextBtn"
        class="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-green-500 rounded-lg"
        :disabled="currentPage == totalPages"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SearchingBtnVue from "../src/components/SearchingBtn.vue";
export default {
  components: { SearchingBtnVue },
  data() {
    return {
      videosData: [],
      searching: "",
      currentPage: 1,
      totalPages: null,
      selectedOption: "Recently",
    };
  },
  mounted() {
    this.fetchVideos();
  },
  watch: {
    selectedOption() {
      this.currentPage = 1; // Reset to the first page when the option changes
      this.fetchVideos();
    },
    currentPage() {
      this.fetchVideos();
    },
  },

  methods: {
    goToPopularView() {
      this.$router.push("/popular");
    },
    async fetchVideos() {
      let endpoint = "";
      if (this.selectedOption === "Recently") {
        endpoint = `/api/videos/recently?page=${this.currentPage}`;
      } else if (this.selectedOption === "Popular") {
        endpoint = `/api/videos/popular?page=${this.currentPage}`;
      }

      try {
        const response = await axios.get(
          process.env.VUE_APP_BACKEND_API + endpoint
        );

        if (this.selectedOption === "Recently") {
          this.videosData = response.data.sortByDate;
        } else if (this.selectedOption === "Popular") {
          this.videosData = response.data.sortByPopular;
        }

        this.totalPages = response.data.totalPages;
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    },
    async getDataId(id) {
      this.$router.push({
        name: "showing",
        params: { id: id },
      });
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    async search() {
      const response = await axios.get(
        process.env.VUE_APP_BACKEND_API +
          `/api/videos/search?query=${this.searching}`
      );
      this.videosData = response.data;
    },
    async handleSearch(query) {
      this.searching = query;
      if (this.searching == "") {
        window.location.reload();
      }
      await this.search();
    },
    prevBtn() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchVideos();
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    nextBtn() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchVideos();
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    getvideo(id) {
      this.$router.push({ name: "showing", params: { id: id } });
    },
    limitLength(text, maxLength) {
      if (!text) {
        return "";
      }
      return text.length <= maxLength
        ? text
        : text.substring(0, maxLength) + "...";
    },
  },
};
</script>
