<template>
  <div>
    <!-- AgeVerify component -->
    <div
      v-if="!ageVerified"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-100 z-toper"
    >
      <div class="rounded-lg bg-white p-8 shadow-2xl max-w-sm">
        <h2 class="text-lg font-bold text-black">
          Are you sure you want to do that?
        </h2>

        <p class="mt-2 text-sm text-gray-500">
          This website contains age-restricted materials including nudity and
          explicit depictions of sexual activity. By entering, you affirm that
          you are at least 18 years of age or the age of majority in the
          jurisdiction you are accessing the website from and you consent to
          viewing sexually explicit content.
        </p>

        <div class="mt-4 flex gap-2">
          <button
            type="button"
            class="rounded bg-green-500 hover:bg-green-600 px-4 py-2 text-sm font-medium text-white"
            @click="confirmAge"
          >
            I am 18 or older - enter
          </button>

          <button
            type="button"
            class="rounded bg-gray-300 hover:bg-gray-400 px-4 py-2 text-sm font-medium text-gray-700"
            @click="denyAge"
          >
            I am under 18 - exit
          </button>
        </div>
        <span class="flex justify-center mt-4 text-gray-500 text-sm">
          <p>
            Our parental controls page explains how you can easily block access
            to this site.
          </p>
        </span>
        <p class="text-gray-500 text-sm">
          Check our policies <a class="text-green-400" href="/rule">policy</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ageVerified: false,
    };
  },
  mounted() {
    // Check localStorage on mount
    const verified = sessionStorage.getItem("ageVerified");
    if (verified === "true") {
      this.ageVerified = true;
    }
  },

  methods: {
    confirmAge() {
      this.ageVerified = true; // Update local data to reflect verification status
      sessionStorage.setItem("ageVerified", "true");
    },
    denyAge() {
      window.location.href = "https://www.google.com";
    },
  },
};
</script>
