<template>
  <div class="relative">
    <!-- Navigation bar  -->
    <div class="sticky top-0 z-toper">
      <div class="fixed h-full">
        <sidebar-nav></sidebar-nav>
      </div>
      <navigation-bar></navigation-bar>
    </div>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import NavigationBar from "./components/NavigationBar.vue";
import SidebarNav from "./components/SidebarNav.vue";
export default {
  components: { NavigationBar, SidebarNav },
};
</script>

<style>
body {
  background-color: #1b1b1b;
  color: white;
}
</style>
