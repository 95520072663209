import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../view/HomePage.vue";
import VideoView from "../view/VideoView.vue";
import VideoShowing from "../view/VideoShowing.vue";
import ModelView from "../view/ModelView.vue";
import ModelShowings from "../view/ModelShowing.vue";
import policyComponent from "../src/policy/policyComponent.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
    meta: { requiresAgeVerification: true },
  },
  {
    path: "/video",
    name: "video",
    component: VideoView,
  },

  {
    path: "/showing/:id",
    name: "showing",
    component: VideoShowing,
    props: true,
  },
  {
    path: "/model",
    name: "model",
    component: ModelView,
  },
  {
    path: "/model/showing/:id",
    name: "modelshowing",
    component: ModelShowings,
  },
  {
    path: "/rule",
    name: "rule",
    component: policyComponent,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
