<template>
  <div>
    <!-- Profile information -->
    <div class="border-2 border-jet_black rounded-md max-h-52">
      <!-- Cover -->
      <div class="w-full z-0">
        <img
          class="h-16 opacity-70 object-cover rounded-md w-full"
          :src="modelInfo.cover"
          alt=""
        />
      </div>
      <div class="flex items-center -mt-5 relative z-50">
        <!-- Background -->
        <div
          class="absolute bottom-0 left-0 w-full h-4/5 bg-gray-500 z-0"
        ></div>
        <!-- Profile Image Container -->
        <div class="flex-shrink-0 relative z-10">
          <img
            class="h-16 w-16 rounded-full object-cover object-center"
            :src="modelInfo.imgUrl"
            alt="Profile Image"
          />
        </div>

        <!-- Name -->
        <div class="ml-4 relative z-10">
          <div class="flex items-center">
            <svg
              class="text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              width="1rem"
              height="1rem"
              viewBox="0 0 24 24"
            >
              <g fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
                <path
                  d="M16 9a4 4 0 1 1-8 0a4 4 0 0 1 8 0m-2 0a2 2 0 1 1-4 0a2 2 0 0 1 4 0"
                />
                <path
                  d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11s11-4.925 11-11S18.075 1 12 1M3 12c0 2.09.713 4.014 1.908 5.542A8.986 8.986 0 0 1 12.065 14a8.984 8.984 0 0 1 7.092 3.458A9 9 0 1 0 3 12m9 9a8.963 8.963 0 0 1-5.672-2.012A6.992 6.992 0 0 1 12.065 16a6.991 6.991 0 0 1 5.689 2.92A8.964 8.964 0 0 1 12 21"
                />
              </g>
            </svg>
            <div>
              <h1 class="text-white font-bold ml-2">
                {{ modelInfo.name }}
              </h1>
            </div>
          </div>
          <!-- Location -->
          <div class="flex items-center mt-2">
            <svg
              class="text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              width="1.2rem"
              height="1.2rem"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M12 11.5A2.5 2.5 0 0 1 9.5 9A2.5 2.5 0 0 1 12 6.5A2.5 2.5 0 0 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7"
              />
            </svg>
            <div>
              <h1 class="text-white font-bold text-sm ml-2">
                {{ modelInfo.location }}
              </h1>
            </div>
          </div>

          <!-- Onlyfans link -->
          <div class="flex items-center mt-2">
            <svg
              class="text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              width="1.2rem"
              height="1.2rem"
              viewBox="0 0 24 24"
            >
              <g
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              >
                <path d="M8.5 6a6.5 6.5 0 1 0 0 13a6.5 6.5 0 0 0 0-13" />
                <path
                  d="M8.5 15a2.5 2.5 0 1 1 0-5a2.5 2.5 0 0 1 0 5m5.5 1c2.5 0 6.42-1.467 7-4h-6c3-1 6.44-3.533 7-6h-4c-3.03 0-3.764-.196-5 1.5"
                />
              </g>
            </svg>
            <div>
              <a
                :href="modelInfo.onlyfans"
                target="_blank"
                class="text-white font-bold text-sm ml-2"
              >
                Onyfans account
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Video -->
  <div class="grid grid-cols-2 gap-4 md:grid-cols-5">
      <div
        class="overflow-hidden rounded-lg shadow mt-4 hover:shadow-lg transition-transform duration-2 hover:scale-105 cursor-pointer"
        v-for="(x, index) in modelVideo"
        :key="index"
        @click="getDataId(x._id)"
      >
        <img alt="" :src="x.thumbnailUrl" class="h-44 w-72 object-cover" />

        <div class="bg-jet_black p-4 min-h-40 sm:p-6 md:min-h-40">
          <span class="flex justify-between text-sm">
            <p>{{ formatDate(x.createdAt) }}</p>
            <p class="text-sm">View : {{ x.view }}</p>
          </span>

          <h3 class="mt-0.5 text-lg font-bold">
            {{ x.title }}
          </h3>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      modelInfo: [],
      modelVideo: [],
    };
  },
  mounted() {
    this.fetchActorId();
  },
  methods: {
    async fetchActorId() {
      const actorId = this.$route.params.id;
      const response = await axios.get(
        process.env.VUE_APP_BACKEND_API + `/api/actors/${actorId}`
      );

      const responseVideo = await axios.get(
        process.env.VUE_APP_BACKEND_API + `/api/videos/actor/${actorId}`
      );
      this.modelInfo = response.data;
      this.modelVideo = responseVideo.data;
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    async getDataId(id) {
      this.$router.push({
        name: "showing",
        params: { id: id },
      });
    },
  },
};
</script>
