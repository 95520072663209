<template>
  <div>
    <div>
      <verify-age></verify-age>
    </div>

    <!-- Sponsor -->
    <div class="grid grid-cols-1 md:grid-cols-2">
      <div>
        <a href="https://t.me/xbet85kh" target="_blank">
          <img class="w-full h-full" src="../src/assets/xbet.gif" alt="" />
        </a>
      </div>
      <div class="hidden md:block">
        <a href="https://t.me/xbet85kh" target="_blank">
          <img class="w-full h-full" src="../src/assets/xbet.gif" alt="" />
        </a>
      </div>
    </div>
    <!-- Recently view -->
    <div>
      <recently-video></recently-video>
    </div>

    <!-- Model -->
    <div>
      <model-component-vue></model-component-vue>
    </div>
    <!-- Popular view -->
    <div>
      <video-component></video-component>
    </div>

    <!-- Footer -->
    <div>
      <footerViewVue></footerViewVue>
    </div>
  </div>
</template>

<script>
import VerifyAge from "../src/components/VerifyAge.vue";
import VideoComponent from "../src/components/PopularVideo.vue";
import RecentlyVideo from "../src/components/RecentlyVideo.vue";
import footerViewVue from "../src/components/footerView.vue";
import modelComponentVue from "../src/components/modelComponent.vue";
export default {
  components: {
    VerifyAge,
    VideoComponent,
    RecentlyVideo,
    footerViewVue,
    modelComponentVue,
  },
};
</script>
