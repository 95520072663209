<template>
  <div
    class="flex flex-col h-full p-3 w-60 fixed top-0 left-0 transition-transform duration-300 bg-darker"
    :class="{ 'translate-x-0': navStatus, '-translate-x-full': !navStatus }"
  >
    <div class="space-y-3">
      <div class="flex items-center justify-between">
        <h2 class="font-bold">VVIPGIRLS</h2>
        <button
          class="p-2 transition-all"
          @click="sideNav"
          :class="{ 'translate-x-0': navStatus, 'translate-x-12': !navStatus }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="w-5 h-5 fill-current text-white"
          >
            <rect width="900" height="72" x="80" y="96"></rect>
            <rect width="900" height="72" x="80" y="240"></rect>
            <rect width="900" height="72" x="80" y="384"></rect>
          </svg>
        </button>
      </div>
      <div class="flex-1">
        <ul class="pt-2 pb-4 space-y-1 text-sm">
          <li class="rounded-sm">
            <a
              rel="noopener noreferrer"
              href="/"
              class="flex items-center p-2 space-x-3 rounded-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="w-5 h-5 fill-current text-white"
              >
                <path
                  d="M469.666,216.45,271.078,33.749a34,34,0,0,0-47.062.98L41.373,217.373,32,226.745V496H208V328h96V496H480V225.958ZM248.038,56.771c.282,0,.108.061-.013.18C247.9,56.832,247.756,56.771,248.038,56.771ZM448,464H336V328a32,32,0,0,0-32-32H208a32,32,0,0,0-32,32V464H64V240L248.038,57.356c.013-.012.014-.023.024-.035L448,240Z"
                ></path>
              </svg>
              <span>Home</span>
            </a>
          </li>
          <li class="rounded-sm">
            <a
              rel="noopener noreferrer"
              href="/video"
              class="flex items-center p-2 space-x-3 rounded-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.25rem"
                height="1.25rem"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M20 4c1.11 0 2 .89 2 2v12c0 1.11-.89 2-2 2H4c-1.11 0-2-.89-2-2V6c0-1.11.89-2 2-2zM8.5 15V9H7.25v3.5L4.75 9H3.5v6h1.25v-3.5L7.3 15zm5-4.74V9h-4v6h4v-1.25H11v-1.11h2.5v-1.26H11v-1.12zm7 3.74V9h-1.25v4.5h-1.12V10h-1.25v3.5h-1.13V9H14.5v5a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1"
                ></path>
              </svg>
              <span>New release</span>
            </a>
          </li>
          <li class="rounded-sm">
            <a
              rel="noopener noreferrer"
              href="/model"
              class="flex items-center p-2 space-x-3 rounded-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1rem"
                height="1rem"
                viewBox="0 0 512 512"
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="32"
                  d="M402 168c-2.93 40.67-33.1 72-66 72s-63.12-31.32-66-72c-3-42.31 26.37-72 66-72s69 30.46 66 72"
                ></path>
                <path
                  fill="none"
                  stroke="currentColor"
                  stroke-miterlimit="10"
                  stroke-width="32"
                  d="M336 304c-65.17 0-127.84 32.37-143.54 95.41c-2.08 8.34 3.15 16.59 11.72 16.59h263.65c8.57 0 13.77-8.25 11.72-16.59C463.85 335.36 401.18 304 336 304Z"
                ></path>
                <path
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="32"
                  d="M200 185.94c-2.34 32.48-26.72 58.06-53 58.06s-50.7-25.57-53-58.06C91.61 152.15 115.34 128 147 128s55.39 24.77 53 57.94"
                ></path>
                <path
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-miterlimit="10"
                  stroke-width="32"
                  d="M206 306c-18.05-8.27-37.93-11.45-59-11.45c-52 0-102.1 25.85-114.65 76.2c-1.65 6.66 2.53 13.25 9.37 13.25H154"
                ></path>
              </svg>
              <span>Model</span>
            </a>
          </li>
          <hr />
          <li class="rounded-sm">
            <a
              rel="noopener noreferrer"
              href="https://t.me/vvipgirls_support"
              target="_blank"
              class="flex items-center p-2 space-x-3 rounded-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2rem"
                height="1.2rem"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M12 2C6.486 2 2 6.486 2 12v4.143C2 17.167 2.897 18 4 18h1a1 1 0 0 0 1-1v-5.143a1 1 0 0 0-1-1h-.908C4.648 6.987 7.978 4 12 4s7.352 2.987 7.908 6.857H19a1 1 0 0 0-1 1V18c0 1.103-.897 2-2 2h-2v-1h-4v3h6c2.206 0 4-1.794 4-4c1.103 0 2-.833 2-1.857V12c0-5.514-4.486-10-10-10"
                ></path>
              </svg>
              <span>Support</span>
            </a>
          </li>
          <li class="rounded-sm">
            <a
              rel="noopener noreferrer"
              href="/rule"
              class="flex items-center p-2 space-x-3 rounded-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2rem"
                height="1.2rem"
                viewBox="0 0 32 32"
              >
                <g fill="none" stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-width="2"
                    d="M16 18v-6M6.358 27h19.284c1.516 0 2.48-1.62 1.759-2.953l-9.642-17.8c-.757-1.397-2.761-1.397-3.518 0L4.6 24.047C3.877 25.38 4.842 27 6.358 27Z"
                  ></path>
                  <path
                    fill="currentColor"
                    d="M17 21.5a1 1 0 1 1-2 0a1 1 0 0 1 2 0Z"
                  ></path>
                </g>
              </svg>
              <span>Term & Condition</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navStatus: false,
    };
  },
  methods: {
    sideNav() {
      this.navStatus = !this.navStatus;
    },
  },
};
</script>
